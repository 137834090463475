// import ReactPaginate from "react-paginate";
import { Chart } from "react-google-charts";
import { useRef } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';

const ResultChart = (props) => {
    const tableRef = useRef(null);

    const options = {
        chart: {
          title: "Chart",
          subtitle: "Track Record Machine",
        },
    };

    const optionsPie = {
        title: "Percentage Record Machine",
    };

    return(
        <>
            <div className="bg-white rounded-xl mb-5 p-[16px]">
                <h3 className="font-bold">TOTAL GAME : {props.gamecount}</h3>
            </div>
            <div className="bg-white rounded-xl mb-5 p-[16px]">
                <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={props.chart}
                    options={options}
                />
            </div>

            <div className="bg-white rounded-xl mb-5">
                <Chart
                    chartType="PieChart"
                    data={props.piechart}
                    options={optionsPie}
                    width={"100%"}
                    height={"400px"}
                />
            </div>

            <div className="bg-white rounded-xl">
                <div className="flex flex-row items-center py-[16px] px-[16px] justify-between overflow-auto w-full">
                    <h3 className="text-black font-medium text-[20px] whitespace-nowrap">Chart List</h3>
                    <div className="flex flex-row items-center gap-2">
                        <div className="flex flex-row gap-3 px-3">
                            <div className="flex flex-row items-center gap-3">
                                <p className="whitespace-nowrap">Filter By Time</p>
                                <input type="checkbox" className="cursor-pointer" checked={props.filtertimechart} onChange={(e) => props.filtertimehandlechart(e)}/>
                            </div>
                        </div>
                        {props.filtertimechart ?
                        <div className="flex flex-col gap-3 border-2 p-2">
                            <div className="flex flex-row gap-3">
                                <div className="flex flex-col border-r-2 pr-2">
                                    <label>Time Begin</label>
                                    <input type="datetime-local" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.timebeginchart} onChange={(e) => props.handletimebeginchart(e)}/>
                                </div>
                                <div className="flex flex-col">
                                    <label>Time End</label>
                                    <input type="datetime-local" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.timeendchart} onChange={(e) => props.handletimeendchart(e)}/>
                                </div>
                            </div>
                            <button className="h-[30px] pl-4 pr-4 bg-blue-500 w-full text-white" onClick={props.filterbtntimehandlechart}>Filter</button>
                        </div> 
                        :
                        <input type="date" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.datenow} onChange={(e) => props.handledatechart(e)}/> 
                        }
                        <DownloadTableExcel
                            filename="ResultChart"
                            sheet="Active"
                            currentTableRef={tableRef.current}
                        >
                            <span className="cursor-pointer">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.6279 15.7327L18.1978 12.5786C18.1525 12.5461 18.0991 12.5269 18.0435 12.5229C17.9879 12.519 17.9323 12.5305 17.8829 12.5563C17.7811 12.6086 17.7187 12.7229 17.7185 12.8378L17.7206 14.3309H13.7352C13.5658 14.3309 13.4014 14.4501 13.4014 14.6198V17.3659C13.4014 17.5356 13.5658 17.6525 13.7352 17.6525H17.761V19.1573C17.761 19.272 17.8051 19.3773 17.9071 19.4299C18.0091 19.4825 18.1118 19.4736 18.2052 19.4066L22.6289 16.2319C22.7093 16.1745 22.7434 16.0817 22.7434 15.9828V15.9823C22.7434 15.8829 22.7088 15.7903 22.6279 15.7327Z" fill="black"/>
                                    <path d="M15.3743 18.8527H13.8167C13.7066 18.8527 13.601 18.8965 13.5232 18.9743C13.4453 19.0522 13.4015 19.1578 13.4015 19.2679H13.3999V19.9903H3.64579V8.67839H7.89931C8.00943 8.67839 8.11503 8.63465 8.1929 8.55679C8.27076 8.47892 8.31451 8.37331 8.31451 8.2632V4.00968H13.3996V12.7351H13.4023C13.4046 12.8435 13.4492 12.9466 13.5267 13.0225C13.6041 13.0983 13.7081 13.1409 13.8165 13.141H15.3741C15.6004 13.141 15.7833 12.96 15.7883 12.7351H15.7893V3H15.7881V2.03519C15.7881 1.92508 15.7444 1.81947 15.6665 1.7416C15.5886 1.66374 15.483 1.62 15.3729 1.62H7.48411L1.25635 7.848V21.9646C1.25635 22.194 1.44211 22.3798 1.67155 22.3798H15.3729C15.483 22.3798 15.5886 22.336 15.6665 22.2581C15.7444 22.1803 15.7881 22.0747 15.7881 21.9646V21.377H15.7893V19.2674C15.7892 19.1574 15.7455 19.0519 15.6677 18.9742C15.5899 18.8964 15.4844 18.8527 15.3743 18.8527Z" fill="black"/>
                                    <path d="M6.01079 14.8364L6.54891 15.7312H6.568L7.10852 14.8364H7.61323L6.86033 16.0582L7.62516 17.28H7.1121L6.568 16.3911H6.54891L6.00482 17.28H5.49414L6.26613 16.0582L5.50369 14.8364H6.01079Z" fill="black"/>
                                    <path d="M7.96075 17.28V14.8364H8.40342V16.9089H9.47967V17.28H7.96075Z" fill="black"/>
                                    <path d="M11.1919 15.5081C11.1807 15.4039 11.1338 15.3228 11.0511 15.2647C10.9692 15.2066 10.8626 15.1776 10.7313 15.1776C10.639 15.1776 10.5599 15.1915 10.4939 15.2194C10.4278 15.2472 10.3773 15.285 10.3423 15.3327C10.3073 15.3804 10.2894 15.4349 10.2886 15.4962C10.2886 15.5471 10.3002 15.5912 10.3232 15.6286C10.3471 15.666 10.3793 15.6978 10.4199 15.7241C10.4605 15.7495 10.5054 15.771 10.5547 15.7885C10.604 15.806 10.6538 15.8207 10.7039 15.8327L10.933 15.8899C11.0252 15.9114 11.1139 15.9404 11.199 15.977C11.285 16.0136 11.3617 16.0598 11.4293 16.1154C11.4977 16.1711 11.5518 16.2383 11.5916 16.3171C11.6314 16.3958 11.6513 16.4881 11.6513 16.5939C11.6513 16.7371 11.6147 16.8632 11.5415 16.9722C11.4683 17.0803 11.3625 17.1651 11.2241 17.2263C11.0865 17.2868 10.9198 17.317 10.7242 17.317C10.534 17.317 10.369 17.2876 10.229 17.2287C10.0898 17.1698 9.9808 17.0839 9.90205 16.971C9.8241 16.858 9.78194 16.7204 9.77558 16.5581H10.2111C10.2175 16.6432 10.2437 16.714 10.2898 16.7705C10.336 16.827 10.396 16.8691 10.47 16.897C10.5448 16.9248 10.6283 16.9387 10.7206 16.9387C10.8168 16.9387 10.9011 16.9244 10.9735 16.8958C11.0467 16.8664 11.104 16.8258 11.1453 16.7741C11.1867 16.7216 11.2078 16.6603 11.2086 16.5903C11.2078 16.5267 11.1891 16.4742 11.1525 16.4328C11.1159 16.3907 11.0646 16.3557 10.9986 16.3278C10.9334 16.2992 10.857 16.2737 10.7695 16.2515L10.4915 16.1799C10.2902 16.1282 10.1311 16.0498 10.0142 15.9448C9.89808 15.839 9.84001 15.6986 9.84001 15.5236C9.84001 15.3797 9.87898 15.2536 9.95694 15.1454C10.0357 15.0372 10.1427 14.9533 10.2779 14.8936C10.4131 14.8332 10.5663 14.8029 10.7373 14.8029C10.9107 14.8029 11.0626 14.8332 11.1931 14.8936C11.3243 14.9533 11.4273 15.0364 11.5021 15.143C11.5769 15.2488 11.6155 15.3705 11.6178 15.5081H11.1919Z" fill="black"/>
                                </svg>
                            </span>
                        </DownloadTableExcel>
                    </div>
                </div>

                <div className="overflow-auto pb-5">
                    <table className="text-left text-sm font-light min-w-full" ref={tableRef}>
                        <thead className="bg-[#F9FAFB]">
                            <tr>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">DATE & TIME</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">CODE</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">VALUE</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">ACCUMULATION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.dataresultchart.map((result, key) => (
                                <tr className="border-y-2" key={key}>
                                    <td className="whitespace-nowrap px-4 py-4">
                                        {new Date(result.colgameresultlasttimestamp).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        })}
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameresultcode}</td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameresultvalue}</td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameresultaccumulation}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* <div className="flex flex-col lg:flex-row lg:justify-between items-center gap-5 h-[100px] mt-5 lg:px-[16px]">
                    <div>
                        Showing <span className="font-bold">1-5</span> of <span className="font-bold">25</span>
                    </div>
                    <div>
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={5}
                            containerClassName={'flex flex-row items-center space-x-2'}
                            pageLinkClassName={'px-3 py-2 rounded-md bg-white text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-300 border border-gray-300 border-solid'}
                            activeLinkClassName={'px-3 py-2 rounded-md bg-white text-gray-600 border border-gray-300 border-solid'}
                            breakLinkClassName={'px-3 py-2'}
                            previousLinkClassName={'px-3 py-2 rounded-l-md bg-white text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-300 border border-gray-300 border-solid'}
                            nextLinkClassName={'px-3 py-2 rounded-r-md bg-white text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-300 border border-gray-300 border-solid'}
                            disabledClassName={'disabled'}
                        />
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default ResultChart;