import { BrowserRouter, Routes, Route  } from "react-router-dom";
import { Provider } from "react-redux";
import { legacy_createStore as createStore } from 'redux';

import Home from "./components/Home/Home";
import Index from "./components/Detail";
import AuthUrlDynamic from "./components/Auth/AuthUrlDynamic";
import AuthUrl from "./components/Auth/AuthUrl";
import AuthUrlRedDetail from "./components/Auth/AuthUrlRedDetail";
import Barchart from "./external/barchart";
import Login from "./components/Login/Login";
import Documentation from "./components/Documentation";

const globalState = {
	uuid : null
}

const rootReducer = (state = globalState, action) => {
	switch(action.type){
		case 'AUTH':
			return {
				...state,
				uuid: action.newUuid
			}
		default:
			return state;
	}
}

const store = createStore(rootReducer);

const App = () => {
	return(
		<Provider store={store}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Login/>}/>
					<Route path="/home" element={<Home/>}/>
					<Route path="/detail/:id" element={<Index/>}/>
					<Route path="/machine/:id/barchart" element={<Barchart/>}/>

					<Route path="/auth/access" element={<AuthUrl/>}/>
					<Route path="/detail/:id/auth/access" element={<AuthUrlRedDetail/>}/>
					<Route path="/log/auth" element={<AuthUrlDynamic/>}/>

					<Route path="/documentation" element={<Documentation/>}/>
				</Routes>
			</BrowserRouter>
		</Provider>
	);
}

export default App;