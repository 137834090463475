import { useRef, useState, useEffect } from "react";
import { DownloadTableExcel } from 'react-export-table-to-excel';

import ReactPaginate from "react-paginate";
import ModalAdjust from "../../Modal/ModalAdjust";
import ModalAdjustV2 from "../../Modal/ModalAdjustV2";
import ModalRound from "../../Modal/ModalRound";
import axios from "axios";

const LastResult = (props) => {
    const tableRef = useRef(null);
    const [endCound, setEndCount] = useState(0);
    const [modal, setModal] = useState(false);
    const [roundDetail, setRoundDetail] = useState([]);

    useEffect(() => {
        let endCount = ((props.pagenumber - 1) + parseInt(props.countrecord));
        setEndCount(endCount);
    }, [props]);

    const redirectGdrive = () => {
        window.open('https://bit.ly/log_spacearcade');
    }

    const roundDetailHandle = async (id) => {
        setModal(true);

        let body = {
            'SECRET_KEY' : process.env.REACT_APP_SECRETKEY,
            'round_code' : id,
        }

        let hostname = process.env.REACT_APP_HOST;
        let response = await axios.post(hostname+'/result/round/detail', body);

        if(response.data.status)
        {
            setRoundDetail(response.data.result);
        }    
    }

    const cancelModal = () => {
        setModal(false);
    }
    return(
        <>
            <div className="bg-white rounded-xl">
                <div className="flex flex-row items-center py-[16px] px-[16px] justify-between overflow-auto w-full">
                    <div className="flex flex-row items-center gap-3">
                        <h3 className="text-black font-medium text-[20px] whitespace-nowrap">Last Result</h3>
                        <div onClick={props.modaladjusthandle} className="cursor-pointer">
                            <svg width="30" height="30" viewBox="0 0 358 358" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M267.667 121.367C267.667 102.745 267.667 93.4341 264.043 86.3216C260.855 80.0652 255.768 74.9786 249.512 71.7908C242.399 68.1667 233.088 68.1667 214.467 68.1667H143.533C124.912 68.1667 115.601 68.1667 108.488 71.7908C102.232 74.9786 97.1452 80.0652 93.9574 86.3216C90.3334 93.4341 90.3334 102.745 90.3334 121.367V236.633C90.3334 255.255 90.3334 264.566 93.9574 271.679C97.1452 277.935 102.232 283.022 108.488 286.209C115.601 289.833 124.912 289.833 143.533 289.833" stroke="black" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M172.427 250.586C155.364 220.695 165.49 182.474 195.043 165.216C212.83 154.829 233.602 154.404 251.042 162.23M156.833 251.201L176.693 256.583L182.015 236.496" stroke="black" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M279.782 196.081C296.844 225.971 286.719 264.193 257.166 281.45C239.378 291.837 218.607 292.262 201.167 284.437M295.375 195.466L275.515 190.083L270.194 210.17" stroke="black" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <img src={process.env.PUBLIC_URL+'/img/icon-gdrive.png'} alt="google-drive" className="w-[30px] h-[30px] cursor-pointer" onClick={redirectGdrive}/>
                        <span className="text-black">Video logs are only available for the last 2 days</span>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                        <div className="flex flex-row gap-3 px-3">
                            <div className="flex flex-row items-center gap-3">
                                <p className="whitespace-nowrap">Filter By Time</p>
                                <input type="checkbox" className="cursor-pointer" checked={props.filtertime} onChange={(e) => props.filtertimehandle(e)}/>
                            </div>
                        </div>
                        {props.filtertime ?
                        <div className="flex flex-col gap-3 border-2 p-2">
                            <div className="flex flex-row gap-3">
                                <div className="flex flex-col border-r-2 pr-2">
                                    <label>Time Begin</label>
                                    <input type="datetime-local" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.timebegin} onChange={(e) => props.handletimebegin(e)}/>
                                </div>
                                <div className="flex flex-col">
                                    <label>Time End</label>
                                    <input type="datetime-local" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.timeend} onChange={(e) => props.handletimeend(e)}/>
                                </div>
                            </div>
                            <button className="h-[30px] pl-4 pr-4 bg-blue-500 w-full text-white" onClick={props.filterbtntimehandle}>Filter</button>
                        </div> 
                        :
                        <input type="date" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.datenow} onChange={(e) => props.handledate(e)}/> 
                        }
                        <select className="border-2 h-[30px] w-[90px] sm:w-[90px] pl-4 pr-4" onChange={(e) => props.handlerecordrow(e)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                        </select>
                        <DownloadTableExcel
                            filename="LastResult"
                            sheet="Active"
                            currentTableRef={tableRef.current}
                        >
                            <span className="cursor-pointer">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.6279 15.7327L18.1978 12.5786C18.1525 12.5461 18.0991 12.5269 18.0435 12.5229C17.9879 12.519 17.9323 12.5305 17.8829 12.5563C17.7811 12.6086 17.7187 12.7229 17.7185 12.8378L17.7206 14.3309H13.7352C13.5658 14.3309 13.4014 14.4501 13.4014 14.6198V17.3659C13.4014 17.5356 13.5658 17.6525 13.7352 17.6525H17.761V19.1573C17.761 19.272 17.8051 19.3773 17.9071 19.4299C18.0091 19.4825 18.1118 19.4736 18.2052 19.4066L22.6289 16.2319C22.7093 16.1745 22.7434 16.0817 22.7434 15.9828V15.9823C22.7434 15.8829 22.7088 15.7903 22.6279 15.7327Z" fill="black"/>
                                    <path d="M15.3743 18.8527H13.8167C13.7066 18.8527 13.601 18.8965 13.5232 18.9743C13.4453 19.0522 13.4015 19.1578 13.4015 19.2679H13.3999V19.9903H3.64579V8.67839H7.89931C8.00943 8.67839 8.11503 8.63465 8.1929 8.55679C8.27076 8.47892 8.31451 8.37331 8.31451 8.2632V4.00968H13.3996V12.7351H13.4023C13.4046 12.8435 13.4492 12.9466 13.5267 13.0225C13.6041 13.0983 13.7081 13.1409 13.8165 13.141H15.3741C15.6004 13.141 15.7833 12.96 15.7883 12.7351H15.7893V3H15.7881V2.03519C15.7881 1.92508 15.7444 1.81947 15.6665 1.7416C15.5886 1.66374 15.483 1.62 15.3729 1.62H7.48411L1.25635 7.848V21.9646C1.25635 22.194 1.44211 22.3798 1.67155 22.3798H15.3729C15.483 22.3798 15.5886 22.336 15.6665 22.2581C15.7444 22.1803 15.7881 22.0747 15.7881 21.9646V21.377H15.7893V19.2674C15.7892 19.1574 15.7455 19.0519 15.6677 18.9742C15.5899 18.8964 15.4844 18.8527 15.3743 18.8527Z" fill="black"/>
                                    <path d="M6.01079 14.8364L6.54891 15.7312H6.568L7.10852 14.8364H7.61323L6.86033 16.0582L7.62516 17.28H7.1121L6.568 16.3911H6.54891L6.00482 17.28H5.49414L6.26613 16.0582L5.50369 14.8364H6.01079Z" fill="black"/>
                                    <path d="M7.96075 17.28V14.8364H8.40342V16.9089H9.47967V17.28H7.96075Z" fill="black"/>
                                    <path d="M11.1919 15.5081C11.1807 15.4039 11.1338 15.3228 11.0511 15.2647C10.9692 15.2066 10.8626 15.1776 10.7313 15.1776C10.639 15.1776 10.5599 15.1915 10.4939 15.2194C10.4278 15.2472 10.3773 15.285 10.3423 15.3327C10.3073 15.3804 10.2894 15.4349 10.2886 15.4962C10.2886 15.5471 10.3002 15.5912 10.3232 15.6286C10.3471 15.666 10.3793 15.6978 10.4199 15.7241C10.4605 15.7495 10.5054 15.771 10.5547 15.7885C10.604 15.806 10.6538 15.8207 10.7039 15.8327L10.933 15.8899C11.0252 15.9114 11.1139 15.9404 11.199 15.977C11.285 16.0136 11.3617 16.0598 11.4293 16.1154C11.4977 16.1711 11.5518 16.2383 11.5916 16.3171C11.6314 16.3958 11.6513 16.4881 11.6513 16.5939C11.6513 16.7371 11.6147 16.8632 11.5415 16.9722C11.4683 17.0803 11.3625 17.1651 11.2241 17.2263C11.0865 17.2868 10.9198 17.317 10.7242 17.317C10.534 17.317 10.369 17.2876 10.229 17.2287C10.0898 17.1698 9.9808 17.0839 9.90205 16.971C9.8241 16.858 9.78194 16.7204 9.77558 16.5581H10.2111C10.2175 16.6432 10.2437 16.714 10.2898 16.7705C10.336 16.827 10.396 16.8691 10.47 16.897C10.5448 16.9248 10.6283 16.9387 10.7206 16.9387C10.8168 16.9387 10.9011 16.9244 10.9735 16.8958C11.0467 16.8664 11.104 16.8258 11.1453 16.7741C11.1867 16.7216 11.2078 16.6603 11.2086 16.5903C11.2078 16.5267 11.1891 16.4742 11.1525 16.4328C11.1159 16.3907 11.0646 16.3557 10.9986 16.3278C10.9334 16.2992 10.857 16.2737 10.7695 16.2515L10.4915 16.1799C10.2902 16.1282 10.1311 16.0498 10.0142 15.9448C9.89808 15.839 9.84001 15.6986 9.84001 15.5236C9.84001 15.3797 9.87898 15.2536 9.95694 15.1454C10.0357 15.0372 10.1427 14.9533 10.2779 14.8936C10.4131 14.8332 10.5663 14.8029 10.7373 14.8029C10.9107 14.8029 11.0626 14.8332 11.1931 14.8936C11.3243 14.9533 11.4273 15.0364 11.5021 15.143C11.5769 15.2488 11.6155 15.3705 11.6178 15.5081H11.1919Z" fill="black"/>
                                </svg>
                            </span>
                        </DownloadTableExcel>
                    </div>
                </div>

                <div className="overflow-x-auto pb-5 w-full">
                    <table className="text-left text-sm font-light min-w-full" ref={tableRef}>
                        <thead className="bg-[#F9FAFB]">
                            <tr>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">DATE & TIME</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap w-[300px]">ROUND CODE</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">RESULT CODE</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">RESULT VALUE</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap"></th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">TYPE</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">BY</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">TIMESTAMP</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">GAME CONFIG 01</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">GAME CONFIG 02</th>
                                <th scope="col" class="px-4 py-4 whitespace-nowrap">GAME CONFIG 03</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.datalastresult.map((result, key) => (
                                <tr className="border-y-2" key={key}>
                                    <td className="whitespace-nowrap px-4 py-4">
                                        {new Date(result.colgameendtimestamp).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        })}
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-4 min-w-[300px]">
                                        <div className="grid grid-cols-2 items-center justify-between gap-10">
                                            {result.colgameendROUND_CODE}
                                            <div className="cursor-pointer" onClick={() => props.requestloghandle(result.colgameendROUND_CODE)}>
                                                <img src={process.env.PUBLIC_URL+'/img/icon-request.png'} alt="request-icon" className="w-[30px] h-[30px]"/>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameendRESULT_CODE}</td>
                                    <td className="whitespace-nowrap px-4 py-4 min-w-[100px]">
                                        <div className="grid grid-cols-2 items-center justify-between gap-10">
                                            <div className="w-full">
                                                {result.colgameendRESULT_VALUE}
                                            </div>
                                            <div className="cursor-pointer" onClick={() => props.adjusthandle(result.colgameendROUND_CODE)}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11 3.99998H6.8C5.11984 3.99998 4.27976 3.99998 3.63803 4.32696C3.07354 4.61458 2.6146 5.07353 2.32698 5.63801C2 6.27975 2 7.11983 2 8.79998V17.2C2 18.8801 2 19.7202 2.32698 20.362C2.6146 20.9264 3.07354 21.3854 3.63803 21.673C4.27976 22 5.11984 22 6.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9264 19.673 20.362C20 19.7202 20 18.8801 20 17.2V13M7.99997 16H9.67452C10.1637 16 10.4083 16 10.6385 15.9447C10.8425 15.8957 11.0376 15.8149 11.2166 15.7053C11.4184 15.5816 11.5914 15.4086 11.9373 15.0627L21.5 5.49998C22.3284 4.67156 22.3284 3.32841 21.5 2.49998C20.6716 1.67156 19.3284 1.67155 18.5 2.49998L8.93723 12.0627C8.59133 12.4086 8.41838 12.5816 8.29469 12.7834C8.18504 12.9624 8.10423 13.1574 8.05523 13.3615C7.99997 13.5917 7.99997 13.8363 7.99997 14.3255V16Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-4 min-w-[10px]">
                                        <div className="cursor-pointer" onClick={() => roundDetailHandle(result.colgameendROUND_CODE)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0-2c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/></svg>
                                        </div>
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameendRESULT_TYPE}</td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameendRESULT_by}</td>
                                    <td className="whitespace-nowrap px-4 py-4">
                                        {new Date(result.colgameendRESULT_timestamp).toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                        })}
                                    </td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameendconfig01}</td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameendconfig02}</td>
                                    <td className="whitespace-nowrap px-4 py-4">{result.colgameendconfig03}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="flex flex-col lg:flex-row lg:justify-between items-center gap-5 h-[100px] lg:px-[16px]">
                    <div>
                        Showing <span className="font-bold">{props.pagenumber}-{endCound}</span> of <span className="font-bold">{props.totaldata}</span>
                    </div>
                    <div className="overflow-auto w-[250px]  text-center md:w-auto">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={Math.ceil(props.totaldata) / 10}
                            containerClassName={'flex inline-flex bg-white shadow rounded'}
                            previousClassName={'px-3 py-2 rounded-l-md border border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800'}
                            nextClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-r border-gray-200 rounded-r-md'}
                            pageClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                            breakClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                            onPageChange={props.lastresultpageclick}
                            activeClassName={'bg-[#E1EFFE]'}
                        />
                    </div>
                </div>
            </div>
            <ModalAdjust modal={props.modal} cancel={props.cancel} newresultvalue={props.newresultvalue} bywriter={props.bywriter} send={props.send}/>
            <ModalAdjustV2 modal={props.modaladjustv2} cancel={props.cancelmodaladjust} data={props.dataresultlist} newresultcode={props.newresultcode} bywriter={props.bywriterv2} send={props.sendv2}/>
            <ModalRound modal={modal} cancel={cancelModal} data={roundDetail}/>
        </>
    );
}

export default LastResult;