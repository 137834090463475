import { Chart } from "react-google-charts";

const SuccessRate = (props) => {
    return(
        <>
            <div className="bg-white rounded-xl mb-5">
                <div className="flex flex-row items-center py-[16px] px-[16px] justify-between overflow-auto w-full">
                    <h3 className="text-black font-medium text-[20px] whitespace-nowrap">Success Rate</h3>
                    <div className="flex flex-row items-center gap-2">
                        <input type="date" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.datenow} onChange={(e) => props.handledatechart(e)}/> 
                    </div>
                </div>
            </div>
            <div className="bg-white rounded-xl p-[16px]">
                <h3 className="text-black font-medium text-[20px] whitespace-nowrap">{props.successrate.title}</h3>
                <Chart
                    chartType="LineChart"
                    width={"100%"}
                    height={"400px"}
                    data={props.successrate.data}
                />
            </div>
        </>
    );
}

export default SuccessRate;