import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotAuthenticated = ({ children }) => {
    const uuid = useSelector(state => state.uuid);
    const navigate = useNavigate();

    useEffect(() => {
        if(uuid === null){
            navigate('/');
        }
    }, [uuid, navigate]);

    return children;
}

export default NotAuthenticated;