import { useEffect, useCallback } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthUrl = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkAccess = useCallback(() => {
        const url = new URL(window.location.href);
        const psParam = url.searchParams.get("ps");
        const redirect = url.searchParams.get("redirect");
        const id = url.searchParams.get("id");

        if((psParam && redirect) || id){
            dispatch({
                type: 'AUTH',
                newUuid : 'Astronaut789'
            });

            if(redirect === 'home'){
                navigate('/home');
            }

            if(id){
                if(redirect === 'detail'){
                    navigate('/detail/'+id);
                }
    
                if(redirect === 'chart'){
                    navigate('/machine/'+id+'/barchart');
                }
            }else{
                navigate('/home');
            }
        }else{
            navigate('/');
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        checkAccess();
    }, [checkAccess]);

    return(
        <p>Check access, please wait...</p>
    )
};

export default AuthUrl;