import { Chart } from "react-google-charts";

const ResultAnalysis = (props) => {
    return(
        <>
            <div className="bg-white rounded-xl mb-5 p-[16px]">
                <div className="flex flex-row items-center justify-between">
                    <h3 className="text-black font-medium text-[20px] whitespace-nowrap">Result Analysis</h3>
                    <div className="flex flex-row items-center py-[16px] px-[16px] justify-between overflow-auto">
                        <div className="flex flex-row items-center gap-2">
                            <div className="flex flex-row gap-3 px-3">
                                <div className="flex flex-row items-center gap-3">
                                    <p className="whitespace-nowrap">Filter By Time</p>
                                    <input type="checkbox" className="cursor-pointer" checked={props.filtertimechart} onChange={(e) => props.filtertimehandlechart(e)}/>
                                </div>
                            </div>
                            {props.filtertimechart ?
                            <div className="flex flex-col gap-3 border-2 p-2">
                                <div className="flex flex-row gap-3">
                                    <div className="flex flex-col border-r-2 pr-2">
                                        <label>Time Begin</label>
                                        <input type="datetime-local" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.timebeginchart} onChange={(e) => props.handletimebeginchart(e)}/>
                                    </div>
                                    <div className="flex flex-col">
                                        <label>Time End</label>
                                        <input type="datetime-local" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.timeendchart} onChange={(e) => props.handletimeendchart(e)}/>
                                    </div>
                                </div>
                                <button className="h-[30px] pl-4 pr-4 bg-blue-500 w-full text-white" onClick={props.filterbtntimehandlechart}>Filter</button>
                            </div> 
                            :
                            <input type="date" className="border-2 h-[30px] w-[150px] sm:w-[200px] pl-4 pr-4" value={props.datenow} onChange={(e) => props.handledatechart(e)}/> 
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="flex flex-col gap-5">
                {props.datacategory.map((category) => {
                    const resultData = props.datachartanalysis[category.colresultcategorycode];

                    return (
                        <div className="bg-white rounded-xl p-[16px]" key={category.colresultcategorycode}>
                            <div className="flex flex-row gap-2 items-center">
                                <h3 className="text-black font-medium text-[20px] whitespace-nowrap">
                                    {category.colresultcategorydescription}
                                </h3>
                                <button
                                    className="bg-blue-500 text-white p-2 rounded-md"
                                    onClick={() => props.showcharthandle(category.colresultcategorycode)}
                                >
                                    {props.categorycode.includes(category.colresultcategorycode) ? 'Hide Chart' : 'Show Chart'}
                                </button>
                            </div>
                            {props.categorycode.includes(category.colresultcategorycode) && resultData ? (
                                <>
                                    <span>{resultData.data.title}</span>
                                    <div>
                                        {resultData.data.type === 'Stacked column charts' ? (
                                            <Chart
                                                chartType="ColumnChart"
                                                width={"100%"}
                                                height={"400px"}
                                                data={resultData.data.data}
                                                options={{
                                                    isStacked: true,
                                                    bars: "vertical",
                                                }}
                                            />
                                        ) : (
                                            <Chart
                                                chartType="PieChart"
                                                width={"100%"}
                                                height={"400px"}
                                                data={resultData.data.data}
                                            />
                                        )}
                                    </div>
                                </>
                            ) : (
                                <span>No data available for this category.</span>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default ResultAnalysis;