const Detail = (props) => {
    const defaultSocketMachine = {
        'CODE' : '',
        'HEADER' : '',
        'ROUND_CODE' : '',
        'STATUS' : '',
        'MESSAGE' : '',
        'CD' : '',
        'RESULT_CODE' : '',
        'RESULT_VALUE' : '',
        'RESULT_LAST': '',
        'RESULT_ACCUM' : '',
        'TIME_OPEN_BET' : '',
        'TIME_CLOSING_BET' : '',
        'TIME_BL_RLING' : '',
        'TIME_REWARD' : '',
        'LOOP' : '',
        'config01' : '',
        'config02' : '',
        'config03' : ''
    }
    
    const resultMachineRealTime = (result) => {
        const tempSocket = props.socketmachine.filter(item => item.CODE === result.colmachinecode).slice().pop();

        return(
            <>
                {
                tempSocket !== undefined ?
                    <pre className={tempSocket.STATUS === 'ERROR' ? 'text-red-600 font-extrabold' : ''}>
                        {JSON.stringify(tempSocket, null, 2)}
                    </pre>
                : 
                    <pre className="text-[#ced6e0]">
                        {JSON.stringify(defaultSocketMachine, null, 2)}    
                    </pre> 
                }
            </>
        )
    }

    return(
        <>
            <div className="bg-white rounded-xl p-[16px]">
                <h3 className="text-black font-medium text-[20px]">Details</h3>
                <div className="mt-[16px] w-full overflow-auto">
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 items-center">
                        <div className="w-full">
                            <table className="w-full">
                                <tbody>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Round Start ID</td>
                                        <th className="text-start text-[#1A56DB]">12345</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Round Start Index</td>
                                        <th className="text-start">SL02123456789456</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Header</td>
                                        <th className="text-start">INFO</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Code</td>
                                        <th className="text-start">SL02</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Round</td>
                                        <th className="text-start">-</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Status</td>
                                        <th className="text-start">BL_RLING</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Message</td>
                                        <th className="text-start">BALL ROLLING</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">CD</td>
                                        <th className="text-start">0</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Result</td>
                                        <th className="text-start">-</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Open Bet</td>
                                        <th className="text-start">5</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Closing Bet</td>
                                        <th className="text-start">1</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Loop</td>
                                        <th className="text-start">1</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Log</td>
                                        <th className="text-start">5</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Random</td>
                                        <th className="text-start">5</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Config 01</td>
                                        <th className="text-start">0</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Config 02</td>
                                        <th className="text-start">0</th>
                                    </tr>
                                    <tr>
                                        <td className="text-start w-[50%] text-[#6B7280]">Config 03</td>
                                        <th className="text-start">0</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w-full">
                            {resultMachineRealTime(props.datamachine)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Detail;