import { useEffect, useCallback } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import moment from 'moment';

const AuthUrl = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const today = new Date();
    const startDate = moment(today).format('YYYY-MM-DD');

    const checkAccess = useCallback(() => {
        const url = new URL(window.location.href);
        const psParam = url.searchParams.get("ps");

        if(psParam){
            dispatch({
                type: 'AUTH',
                newUuid : 'Astronaut789'
            });

            navigate('/home');
        }else{
            navigate('/');
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        if(process.env.REACT_APP_ENDDATEVERSION <= startDate){
            navigate('/documentation')
        }else{
            checkAccess();
        }
    }, [checkAccess, startDate, navigate]);

    return(
        <p>Check access, please wait...</p>
    )
};

export default AuthUrl;