import { Link } from "react-router-dom";

const Sidebar = () => {
    return(
        <>
            <div className="bg-white h-full w-[60px] border-r-2 border-[#E5E7EB] fixed">
                <div className="w-full h-full pt-[64px]">
                    <div className="h-[74px] border-b-2 border-[#E5E7EB]">
                        <ul className="flex flex-col h-full justify-center">
                            <li className="bg-[#F3F4F6] h-[40px] flex flex-col justify-center items-center">
                                <Link to={'/home'} className="w-full flex flex-col items-center h-[32px] justify-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.40002 11.9999C2.40002 9.45382 3.41145 7.01203 5.2118 5.21168C7.01215 3.41133 9.45395 2.3999 12 2.3999V11.9999H21.6C21.6 14.546 20.5886 16.9878 18.7882 18.7881C16.9879 20.5885 14.5461 21.5999 12 21.5999C9.45395 21.5999 7.01215 20.5885 5.2118 18.7881C3.41145 16.9878 2.40002 14.546 2.40002 11.9999Z" fill="#111928"/>
                                        <path d="M14.4 2.70239C16.0604 3.13255 17.5755 3.99893 18.7883 5.21174C20.0011 6.42454 20.8675 7.93964 21.2976 9.59999H14.4V2.70239Z" fill="#111928"/>
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;