const ModalAdjustV2 = (props) => {
    return(
        <>
            {props.modal ?
            <div className="fixed z-20 inset-0 lg:pl-[60px] overflow-auto" style={{ background: 'rgba(127, 140, 141, 0.3)'}}>
               <div className="m-auto px-5 lg:max-w-[800px] xl:px-20">
                    <div className="bg-white mt-[100px] mb-[100px] rounded-md shadow-md p-5">
                        <div className="flex flex-row items-center justify-between">
                            <h3 className="font-medium">ADJUST RESULT CODE</h3>
                            <button className="cursor-pointer" onClick={props.cancel}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3334 4.66666L4.66669 11.3333M4.66669 4.66666L11.3334 11.3333" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className="mt-[20px]">
                            <div className="mb-3">
                                <label className="pb-2 block">NEW RESULT CODE</label>
                                <select className="w-full border-2 h-[40px] rounded-lg px-2 cursor-pointer" ref={props.newresultcode}>
                                    <option value="">--Option--</option>
                                    {props.data.map((result, key) => (
                                        <option value={result.colresultcode} key={key}>{result.colresultvalue+' - '+result.colresultcode}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="pb-2 block">BY ( writer )</label>
                                <input type="text" className="w-full border-2 h-[40px] rounded-lg px-2" placeholder="Example : Thomas" ref={props.bywriter}/>
                            </div>
                            <div className="mb-3">
                                <button className="w-full border-2 border-blue-500 rounded-lg py-2 bg-blue-500 text-white" onClick={props.send}>SEND</button>
                            </div>
                        </div>
                    </div>
               </div>
            </div> 
            : '' }
        </>
    );
}

export default ModalAdjustV2;