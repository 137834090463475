export default function FormatDateTime(){
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    let hours = today.getHours();
    let minute = today.getMinutes();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    const formattedDate = `${year}-${month}-${day} ${("0" + hours).slice(-2)}:${("0" + minute).slice(-2)}`;

	return formattedDate;
}