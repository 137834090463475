import React, { useEffect, useState, useCallback } from "react";
import { Chart } from "react-google-charts";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from 'moment';
import NotAuthenticated from "../../hoc/NotAuthenticated";

const Barchart = () => {
    const [chart, setChart] = useState([]);
    const { id } = useParams();

    const originalDate = new Date();
    const formattedDate = moment(originalDate).format('YYYY-MM-DD HH:mm');

    const options = {
        chart: {
            title: id,
            subtitle: formattedDate
        },
    };

    const loadResultChart = useCallback( async (date, timerange, timebegin, timeend) => {
        let body = {
            'SECRET_KEY' : process.env.REACT_APP_SECRETKEY,
            'date' : date,
            'machinecode' : id,
            'timerange' : timerange,
            'timebegin' : timebegin,
            'timeend' : timeend
        }

        let hostname = process.env.REACT_APP_HOST;
        let response = await axios.post(hostname+'/result/chart', body);

        if(response.data.status){
            let resultDataChart = [
                ["Code", "Accumulation"]
            ];

            response.data.result.resultlist.forEach((result) => {
                let dataChart = [
                    result.colgameresultcode, result.colgameresultaccumulation
                ];

                resultDataChart.push(dataChart);
            });

            setChart(resultDataChart);
        }
    }, [id]);

    useEffect(() => {
        loadResultChart();
    }, [loadResultChart]);

    document.title = "Barchart "+id

    return(
        <React.Fragment>
            <NotAuthenticated>
            <div className="bg-[#EDF5FE] w-full min-h-full p-[24px]">
                <div className="bg-white mb-5 p-[16px]">
                    <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={chart}
                        options={options}
                    />
                </div>
            </div>
            </NotAuthenticated>
        </React.Fragment>
    );
}

export default Barchart;