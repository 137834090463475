import { useEffect, useState } from "react";

import ModalPicture from "../../Modal/ModalPicture";
import ReactPaginate from "react-paginate";

const Note = (props) => {
    const [endCound, setEndCount] = useState(0);

    useEffect(() => {
        let endCount = ((props.pagenumber - 1) + 10)
        setEndCount(endCount);
    }, [props]);
    return(
        <>
            <div className="bg-white rounded-xl p-[16px]">
                <h3 className="text-black font-medium text-[20px]">Note</h3>
                <div className="mt-[16px] max-h-[533px] overflow-auto">
                    <div className="flex flex-col gap-5">
                        {props.datanote.length > 0 ?
                        props.datanote.map((result, key) => (
                            <div className={result.colnoteispinned === 1 ? 'border-2 rounded-lg p-[12px] bg-[#D1E0FE]' : 'border-2 rounded-lg p-[12px]' } key={key}>
                                <div className="flex flex-row justify-between">
                                    <div>
                                        <p className="text-blue-600 font-medium">{ result.colnoteby }</p>
                                        <p className="font-mediun text-[18px]">{ result.colnotenote }</p>
                                        <p className="text-gray-400">
                                            {new Date(result.colnotecreatedatetime).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                                timeZone: "UTC"
                                            })}
                                        </p>
                                        {result.colnoteimagepath !== '' ?
                                        <button onClick={() => props.modalhandle(result.colnoteimagepath)}>
                                            <img src={result.colnoteimagepath} className="w-[150px] h-[100px] mt-5" alt="imageAttachment"/>
                                        </button> : '' }
                                    </div>

                                    <div className="flex flex-row items-center gap-2">
                                        <div className="cursor-pointer" onClick={() => props.pinhandle(result)}>
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.37658 16.1163L2.71973 21.7732M11.6943 7.14181L10.1334 8.7027C10.0061 8.83003 9.9424 8.89369 9.86986 8.94427C9.80548 8.98917 9.73604 9.02634 9.66297 9.055C9.58065 9.08729 9.49236 9.10495 9.3158 9.14026L5.65133 9.87315C4.69903 10.0636 4.22288 10.1588 4.00012 10.4099C3.80605 10.6286 3.71743 10.9213 3.75758 11.2109C3.80367 11.5434 4.14703 11.8867 4.83375 12.5735L11.9195 19.6592C12.6062 20.3459 12.9496 20.6893 13.282 20.7354C13.5716 20.7755 13.8643 20.6869 14.083 20.4928C14.3341 20.2701 14.4293 19.7939 14.6198 18.8416L15.3527 15.1771C15.388 15.0006 15.4056 14.9123 15.4379 14.83C15.4666 14.7569 15.5038 14.6875 15.5487 14.6231C15.5992 14.5505 15.6629 14.4869 15.7902 14.3596L17.3511 12.7987C17.4325 12.7173 17.4732 12.6766 17.518 12.641C17.5577 12.6095 17.5998 12.581 17.6439 12.5558C17.6935 12.5274 17.7464 12.5048 17.8522 12.4594L20.3466 11.3904C21.0743 11.0785 21.4381 10.9226 21.6034 10.6706C21.7479 10.4503 21.7996 10.1818 21.7473 9.92348C21.6874 9.62813 21.4075 9.34822 20.8477 8.78839L15.7045 3.64526C15.1447 3.08543 14.8648 2.80552 14.5695 2.74565C14.3112 2.69329 14.0427 2.745 13.8223 2.88953C13.5703 3.05481 13.4144 3.41866 13.1025 4.14636L12.0335 6.64071C11.9882 6.74653 11.9655 6.79944 11.9372 6.84905C11.912 6.89313 11.8835 6.93522 11.8519 6.97496C11.8164 7.01971 11.7757 7.06041 11.6943 7.14181Z" stroke="#5A5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div className="cursor-pointer" onClick={() => props.deletehandle(result.colnoteid)}>
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 3.5H15M3 6.5H21M19 6.5L18.2987 17.0193C18.1935 18.5975 18.1409 19.3867 17.8 19.985C17.4999 20.5118 17.0472 20.9353 16.5017 21.1997C15.882 21.5 15.0911 21.5 13.5093 21.5H10.4907C8.90891 21.5 8.11803 21.5 7.49834 21.1997C6.95276 20.9353 6.50009 20.5118 6.19998 19.985C5.85911 19.3867 5.8065 18.5975 5.70129 17.0193L5 6.5M10 11V16M14 11V16" stroke="#5A5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : 
                        <div className="m-auto">
                            <svg width="184" height="202" viewBox="0 0 184 202" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 197.304L4.51982 97.2359C3.69553 83.4427 14.6593 71.8042 28.4771 71.8042H31.8419C38.3351 71.8042 44.5513 74.4352 49.0715 79.0967L50.9285 81.0117C55.4487 85.6732 61.6649 88.3042 68.1581 88.3042H132C140.008 88.3042 146.5 94.7961 146.5 102.804V102.804M10.5 197.304L28.9772 133.617C31.9528 123.361 41.3474 116.304 52.0267 116.304H145.353C160.938 116.304 172.389 130.927 168.654 146.057L160.505 179.057C157.859 189.774 148.244 197.304 137.205 197.304H10.5Z" stroke="#B9C0CE" stroke-width="8"/>
                                <path d="M125.5 4.30422C82.3002 10.7042 87.5002 36.3042 95.5002 48.3042L91.3667 66.9051C91.0094 68.5129 92.6399 69.8393 94.1413 69.1622L116 59.3042C137 63.4709 179.1 63.7042 179.5 31.3042C179.5 6.10422 157.167 2.80422 146 4.30422" stroke="#B9C0CE" stroke-width="8" stroke-linecap="round"/>
                                <path d="M143.5 24.8042L126 42.3042M126 24.8042L145.5 42.3042" stroke="#B9C0CE" stroke-width="8" stroke-linecap="round"/>
                                <path d="M64 150.304H78.5" stroke="#B9C0CE" stroke-width="8" stroke-linecap="round"/>
                                <path d="M119 150.304H133.5" stroke="#B9C0CE" stroke-width="8" stroke-linecap="round"/>
                                <path d="M87 175.304C91 172.138 101.1 167.704 109.5 175.304" stroke="#B9C0CE" stroke-width="8" stroke-linecap="round"/>
                            </svg>
                        </div>
                        }
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row lg:justify-between items-center gap-5 h-[100px] lg:px-[16px]">
                    <div>
                        Showing <span className="font-bold">{props.pagenumber}-{endCound}</span> of <span className="font-bold">{props.totaldata}</span>
                    </div>
                    <div className="overflow-auto w-[250px] text-center md:w-auto">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={Math.ceil(props.totaldata) / 10}
                            containerClassName={'flex inline-flex bg-white shadow rounded'}
                            previousClassName={'px-3 py-2 rounded-l-md border border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800'}
                            nextClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-r border-gray-200 rounded-r-md'}
                            pageClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                            breakClassName={'px-3 py-2 border-t border-b border-r border-gray-200 text-gray-600 hover:bg-gray-100 hover:text-gray-800 border-l border-gray-200'}
                            onPageChange={props.notelistpageclick}
                            activeClassName={'bg-[#E1EFFE]'}
                        />
                    </div>
                </div>

                <hr/>

                <div className="mt-[16px]">
                    <h3 className="text-black font-medium text-[20px]">Add Note</h3>

                    <div className="mt-[16px]">
                        <form onSubmit={(e) => props.submitnotehandle(e)}>
                            <div className="mb-3">
                                <label for="from">From</label>
                                <input type="text" className="border-2 w-full h-[38px] rounded-md px-2 outline-none" ref={props.forminput} placeholder="Your Name..." required/>
                                {props.errorfrom !== '' ? <span className="text-red-500">{props.errorfrom}</span> : ''}
                            </div>
                            <div className="mb-3">
                                <label for="note">Note</label>
                                <div className="relative">
                                    <textarea className="border-2 w-full rounded-md px-2 py-2 outline-none" rows={10} ref={props.formnote} placeholder="Add Note Here..." required></textarea>
                                    <input type="file" className="absolute left-2 bottom-4 " ref={props.formnotefile}/>
                                </div>
                                {props.errornote !== '' ? <span className="text-red-500">{props.errornote}</span> : ''}
                            </div>
                            <div className="mb-3">
                                <button className="bg-blue-500 w-full text-white py-2 rounded-md" type="submit">Add Note</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ModalPicture modal={props.modal} cancel={props.cancel} picture={props.picturemodal}/>
        </>
    );
}

export default Note;