import React from "react";
import { useNavigate } from "react-router-dom";

const Documentation = () => {
    const navigate = useNavigate();
    
    document.title = 'Documentation';
    return(
        <React.Fragment>
            <div className="bg-[#EDF5FE] w-full min-h-full flex flex-col">
                <div className="bg-white w-full sm:w-[500px] shadow-lg m-auto mt-10 p-[10px] rounded-md">
                    <h3 className="text-black font-medium mb-3 text-[20px]">Documentation</h3>

                    <p>Informasi Sistem</p>
                    <p>Ada beberapa perbaikan atau modifikasi beberapa route</p>
                    <br/>
                    <p>Untuk route dibawah ini akan dinonaktifkan pada tanggal</p>
                    <span className="text-red-500 block">{process.env.REACT_APP_ENDDATEVERSION}</span>
                    <br/>
                    <ul>
                        <li>/auth/access?ps=contohPassword</li>
                        <li>/detail/:id/auth/access?ps=contohPassword</li>
                    </ul>
                    <br/>
                    <hr/>
                    <br/>
                    <p>Route Pengganti</p>
                    <p>/log/auth?ps=contohPassword&redirect=page&id=idmachine</p>
                    <br/>
                    <p>Info</p>
                    <table className="w-full text-left mt-2">
                        <thead>
                            <tr>
                                <th className="border-2 py-1 px-2">Params</th>
                                <th className="border-2 py-1 px-2">Keterangan</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border-2 py-1 px-2">ps</td>
                                <td className="border-2 py-1 px-2">Password</td>
                            </tr>
                            <tr>
                                <td className="border-2 py-1 px-2">redirect</td>
                                <td className="border-2 py-1 px-2">home, detail, chart</td>
                            </tr>
                            <tr>
                                <td className="border-2 py-1 px-2">id</td>
                                <td className="border-2 py-1 px-2">SL01, SL02, SL03 DLL</td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <p>Contoh</p>
                    <p>/log/auth?ps=thomas123&redirect=home&id=SL03</p>
                    <br/>
                    <button onClick={() => navigate('/')} type="submit" className="bg-blue-500 w-full h-[40px] rounded-md text-white font-medium">Kembali</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Documentation;