const ModalPicture = (props) => {
    return(
        <>
            {props.modal ?
            <div className="fixed z-20 inset-0 lg:pl-[60px] overflow-auto" style={{ background: 'rgba(127, 140, 141, 0.3)'}}>
               <div className="m-auto px-5 lg:max-w-[2000px] xl:px-20">
                    <div className="bg-white mt-[100px] mb-[100px] rounded-md shadow-md p-5">
                        <div className="flex flex-row items-center justify-between">
                            <h3 className="font-medium">PICTURE</h3>
                            <button className="cursor-pointer" onClick={props.cancel}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3334 4.66666L4.66669 11.3333M4.66669 4.66666L11.3334 11.3333" stroke="#0A0A0A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className="mt-[20px]">
                            <img src={props.picture} className="w-full" alt="imageAttachment"/>
                        </div>
                    </div>
               </div>
            </div> : '' }
        </>
    );
}

export default ModalPicture;