const Navbar = () => {
    return(
        <>
            <div className="bg-white w-full h-[64px] px-[16px] fixed border-b-2 border-[#E5E7EB] z-10">
                <div className="flex flex-row justify-between h-full items-center">
                    <div className="flex flex-row items-center">
                        <img src={process.env.PUBLIC_URL+'/img/logo.png'} alt="logo.png" className="w-[40px] h-[40px] mr-[19px]"/>
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.79999 8.4999C4.79999 8.07556 4.96856 7.66859 5.26862 7.36853C5.56868 7.06847 5.97564 6.8999 6.39999 6.8999H25.6C26.0243 6.8999 26.4313 7.06847 26.7314 7.36853C27.0314 7.66859 27.2 8.07556 27.2 8.4999C27.2 8.92425 27.0314 9.33121 26.7314 9.63127C26.4313 9.93133 26.0243 10.0999 25.6 10.0999H6.39999C5.97564 10.0999 5.56868 9.93133 5.26862 9.63127C4.96856 9.33121 4.79999 8.92425 4.79999 8.4999ZM4.79999 16.4999C4.79999 16.0756 4.96856 15.6686 5.26862 15.3685C5.56868 15.0685 5.97564 14.8999 6.39999 14.8999H25.6C26.0243 14.8999 26.4313 15.0685 26.7314 15.3685C27.0314 15.6686 27.2 16.0756 27.2 16.4999C27.2 16.9242 27.0314 17.3312 26.7314 17.6313C26.4313 17.9313 26.0243 18.0999 25.6 18.0999H6.39999C5.97564 18.0999 5.56868 17.9313 5.26862 17.6313C4.96856 17.3312 4.79999 16.9242 4.79999 16.4999ZM4.79999 24.4999C4.79999 24.0756 4.96856 23.6686 5.26862 23.3685C5.56868 23.0685 5.97564 22.8999 6.39999 22.8999H16C16.4243 22.8999 16.8313 23.0685 17.1314 23.3685C17.4314 23.6686 17.6 24.0756 17.6 24.4999C17.6 24.9242 17.4314 25.3312 17.1314 25.6313C16.8313 25.9313 16.4243 26.0999 16 26.0999H6.39999C5.97564 26.0999 5.56868 25.9313 5.26862 25.6313C4.96856 25.3312 4.79999 24.9242 4.79999 24.4999Z" fill="#111928"/>
                        </svg>
                    </div>
                    <div className="flex flex-row items-center">
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="32" height="32" rx="16" fill="white"/>
                            <path d="M16 6.8999C14.0904 6.8999 12.2591 7.65847 10.9088 9.00873C9.55853 10.359 8.79996 12.1903 8.79996 14.0999V18.4031L7.95156 19.2515C7.78379 19.4193 7.66954 19.6331 7.62326 19.8659C7.57698 20.0986 7.60075 20.3398 7.69155 20.5591C7.78235 20.7783 7.93612 20.9657 8.13341 21.0976C8.33071 21.2294 8.56266 21.2999 8.79996 21.2999H23.2C23.4373 21.2999 23.6692 21.2294 23.8665 21.0976C24.0638 20.9657 24.2176 20.7783 24.3084 20.5591C24.3992 20.3398 24.4229 20.0986 24.3767 19.8659C24.3304 19.6331 24.2161 19.4193 24.0484 19.2515L23.2 18.4031V14.0999C23.2 12.1903 22.4414 10.359 21.0911 9.00873C19.7409 7.65847 17.9095 6.8999 16 6.8999ZM16 26.0999C15.0452 26.0999 14.1295 25.7206 13.4544 25.0455C12.7792 24.3704 12.4 23.4547 12.4 22.4999H19.6C19.6 23.4547 19.2207 24.3704 18.5455 25.0455C17.8704 25.7206 16.9547 26.0999 16 26.0999Z" fill="#111928"/>
                        </svg>
                        <img src={process.env.PUBLIC_URL+'/img/avatar.png'} alt="avatar.png" className="w-[40px] h-[40px] ml-[19px]"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;