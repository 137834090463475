import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import io from 'socket.io-client';
import axios from "axios";

import Navbar from "../Layouts/Navbar";
import Sidebar from "../Layouts/Sidebar";
import NotAuthenticated from "../../hoc/NotAuthenticated";
import Switch from "react-switch";
import Swal from "sweetalert2";

const Home = () => {
    const [machineList, setMachineList] = useState([]);
    const [filterSearch, setFilterSearch] = useState([]);
    const [socketMachine, setSocketMachine] = useState([]);

    const defaultSocketMachine = {
        'CODE' : '',
        'HEADER' : '',
        'ROUND_CODE' : '',
        'STATUS' : '',
        'MESSAGE' : '',
        'CD' : '',
        'RESULT_CODE' : '',
        'RESULT_VALUE' : '',
        'RESULT_LAST': '',
        'RESULT_ACCUM' : '',
        'TIME_OPEN_BET' : '',
        'TIME_CLOSING_BET' : '',
        'TIME_BL_RLING' : '',
        'TIME_REWARD' : '',
        'LIVE' : '',
        'LOOP' : '',
        'config01' : '',
        'config02' : '',
        'config03' : '',
    }

    const loadMachine = useCallback( async () => {
        let body = {
            'SECRET_KEY' : process.env.REACT_APP_SECRETKEY
        }

        let response = await axios.post(process.env.REACT_APP_HOST+'/list/machine', body);

        if(response.data.result.length > 0){
            response.data.result.forEach((result) => {
                const Schema = 'https://';
                const Code = result.colmachinecode.toLowerCase();
                const Host = '.orbiter.spacearcade.online'
                
                const socketConfig = io(Schema+Code+Host);
                socketConfig.on('socketioinfo', (data) => {
                    setSocketMachine(current => [...current, data]);
                });
            });

            setMachineList(response.data.result);
            setFilterSearch(response.data.result);
        }
    }, []);

    useEffect(() => {
        loadMachine();

        return () => {
            loadMachine();
        }
    }, [loadMachine]);

    const searchHandle = (e) => {
        let inputSearch = e.target.value;

        if(inputSearch !== ''){
            let filtered = filterSearch.filter(item => item.colmachinecode.includes(inputSearch.toUpperCase()));
            setFilterSearch(filtered);
        }else{
            setFilterSearch(machineList);
        }
    }

    const resultMachineRealTime = (result) => {
        const tempSocket = socketMachine.filter(item => item.CODE === result.colmachinecode).slice().pop();

        return(
            <>
                {
                tempSocket !== undefined ?
                    <pre className={tempSocket.STATUS === 'ERROR' ? 'text-red-600 font-extrabold' : ''}>
                        {JSON.stringify(tempSocket, null, 2)}
                    </pre>
                : 
                    <pre className="text-[#ced6e0]">
                        {JSON.stringify(defaultSocketMachine, null, 2)}    
                    </pre> 
                }
            </>
        )
    }

    const loopHandle = async (id) => {
        const lastSocket = socketMachine.filter(item => item.CODE === id).slice().pop();

        if(lastSocket.LOOP === 1){
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to stop Loop the engine",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then( async (result) => {
                if (result.isConfirmed) {
                    try {
                        const Schema = 'https://';
                        const Code = id.toLowerCase();
                        const Host = '.orbiter.spacearcade.online'
        
                        const hostname = Schema+Code+Host;
        
                        let body = {
                            'SECRET_KEY' : process.env.REACT_APP_SECRETKEY,
                            'LOOP' : 0
                        }
                        
                        let response = await axios.post(hostname+'/config/loop', body);
        
                        if(response.data.status){
                            Swal.fire(
                                'Success!',
                                response.data.message,
                                'success'
                            )
                        }else{
                            Swal.fire(
                                'Failed!',
                                response.data.message,
                                'warning'
                            )
                        }
                    } catch (error) {
                        Swal.fire(
                            'error!',
                            'Network Error.',
                            'error'
                        )
                    }
                }
            })
        }else{
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to start Loop the engine",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then( async (result) => {
                if (result.isConfirmed) {
                    try {
                        const Schema = 'https://';
                        const Code = id.toLowerCase();
                        const Host = '.orbiter.spacearcade.online'
        
                        const hostname = Schema+Code+Host;
        
                        let body = {
                            'SECRET_KEY' : process.env.REACT_APP_SECRETKEY,
                            'LOOP' : 1
                        }
                        
                        let response = await axios.post(hostname+'/config/loop', body);
        
                        if(response.data.status){
                            Swal.fire(
                                'Success!',
                                response.data.message,
                                'success'
                            )
                        }else{
                            Swal.fire(
                                'Failed!',
                                response.data.message,
                                'warning'
                            )
                        }
                    } catch (error) {
                        Swal.fire(
                            'error!',
                            'Network Error.',
                            'error'
                        )
                    }
                }
            })
        }
    }

    const liveHandle = async (id) => {
        const lastSocket = socketMachine.filter(item => item.CODE === id).slice().pop();

        if(lastSocket.LIVE === 1){
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to stop Live the engine",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then( async (result) => {
                if (result.isConfirmed) {
                    try {
                        const Schema = 'https://';
                        const Code = id.toLowerCase();
                        const Host = '.orbiter.spacearcade.online'
        
                        const hostname = Schema+Code+Host;
        
                        let body = {
                            'SECRET_KEY' : process.env.REACT_APP_SECRETKEY,
                            'LIVE' : 0
                        }
                        
                        let response = await axios.post(hostname+'/config/live', body);
        
                        if(response.data.status){
                            Swal.fire(
                                'Success!',
                                response.data.message,
                                'success'
                            )
                        }else{
                            Swal.fire(
                                'Failed!',
                                response.data.message,
                                'warning'
                            )
                        }
                    } catch (error) {
                        Swal.fire(
                            'error!',
                            'Network Error.',
                            'error'
                        )
                    }
                }
            })
        }else{
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to start Live the engine",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'YES',
                cancelButtonText: 'NO'
            }).then( async (result) => {
                if (result.isConfirmed) {
                    try {
                        const Schema = 'https://';
                        const Code = id.toLowerCase();
                        const Host = '.orbiter.spacearcade.online'
        
                        const hostname = Schema+Code+Host;
        
                        let body = {
                            'SECRET_KEY' : process.env.REACT_APP_SECRETKEY,
                            'LIVE' : 1
                        }
                        
                        let response = await axios.post(hostname+'/config/live', body);
        
                        if(response.data.status){
                            Swal.fire(
                                'Success!',
                                response.data.message,
                                'success'
                            )
                        }else{
                            Swal.fire(
                                'Failed!',
                                response.data.message,
                                'warning'
                            )
                        }
                    } catch (error) {
                        Swal.fire(
                            'error!',
                            'Network Error.',
                            'error'
                        )
                    }
                }
            })
        }
    }

    const startHandle = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to start the engine",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'YES',
            cancelButtonText: 'NO'
        }).then( async (result) => {
            if (result.isConfirmed) {
                 try {
                    const Schema = 'https://';
                    const Code = id.toLowerCase();
                    const Host = '.orbiter.spacearcade.online'

                    const hostname = Schema+Code+Host;

                    let body = {
                        'SECRET_KEY' : process.env.REACT_APP_SECRETKEY,
                    }
                    
                    let response = await axios.post(hostname+'/start', body);

                    if(response.data.status){
                        Swal.fire(
                            'Success!',
                            response.data.message,
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Failed!',
                            response.data.message,
                            'warning'
                        )
                    }
                } catch (error) {
                    Swal.fire(
                        'error!',
                        'Network Error.',
                        'error'
                    )
                }
            }
        })
    }

    const maintenance = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Are you sure you want to perform maintenance",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'YES',
            cancelButtonText: 'NO'
        }).then( async (result) => {
            if (result.isConfirmed) {
                 try {
                    const Schema = 'https://';
                    const Code = id.toLowerCase();
                    const Host = '.orbiter.spacearcade.online'

                    const hostname = Schema+Code+Host;

                    let body = {
                        'SECRET_KEY' : process.env.REACT_APP_SECRETKEY,
                    }
                    
                    let response = await axios.post(hostname+'/config/maintenance', body);

                    if(response.data.status){
                        loadMachine();
                        Swal.fire(
                            'Success!',
                            response.data.message,
                            'success'
                        )
                    }else{
                        loadMachine();
                        Swal.fire(
                            'Failed!',
                            response.data.message,
                            'warning'
                        )
                    }
                } catch (error) {
                    loadMachine();
                    Swal.fire(
                        'error!',
                        'Network Error.',
                        'error'
                    )
                }
            }
        })
    }

    const switch_element = (result) => {
        const tempSocket = socketMachine.filter(item => item.CODE === result.colmachinecode).slice().pop();

        return(
            <>
            { tempSocket !== undefined ? 
            <div className="flex flex-row gap-1 items-center">
                <div className="flex flex-row items-center gap-2">
                    <label className="whitespace-nowrap">Loop : </label>
                    <Switch height={20} width={40} onColor="#c0392b" onChange={() => loopHandle(result.colmachinecode)} checked={tempSocket.LOOP === 1 ? true : false}/>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <label className="whitespace-nowrap">Live : </label>
                    <Switch height={20} width={40} onColor="#c0392b" onChange={() => liveHandle(result.colmachinecode)} checked={tempSocket.LIVE === 1 ? true : false}/>
                </div>
            </div> : '' }
            </>
        )
    }

    const rewardValue = (result) => {
        const tempSocket = socketMachine.filter(item => item.CODE === result.colmachinecode).slice().pop();
    
        return(
            <>
            { tempSocket !== undefined ? 
                <>
                    <h2 className="text-[15px] font-medium text-[#111928]">{tempSocket.ROUND_CODE}</h2>
                    <h2 className="text-[15px] font-medium text-[#111928]">{tempSocket.RESULT_VALUE}</h2>
                </>
            : '' }
            </>
        )
    }

    document.title = 'Orbiter';
    return(
        <>
            <NotAuthenticated>
                <div className="bg-[#EDF5FE] w-full min-h-full">
                    <Navbar/>
                    <Sidebar/>
                    <div className="w-full h-full pt-[64px] pl-[60px] flex flex-col">
                        <div className="py-[24px] px-[23px] h-full flex flex-col">
                            <div className="max-w-[2000px] m-auto w-full">
                                <div className="flex flex-row justify-between items-center">
                                    <div className="flex flex-col">
                                        <h4 className="text-[#171725] font-semibold text-[24px]">Dashboard</h4>
                                        <div className="flex flex-row items-center">
                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.707 2.79303C10.5195 2.60556 10.2651 2.50024 9.99998 2.50024C9.73482 2.50024 9.48051 2.60556 9.29298 2.79303L2.29298 9.79303C2.11082 9.98163 2.01003 10.2342 2.01231 10.4964C2.01458 10.7586 2.11975 11.0094 2.30516 11.1948C2.49057 11.3803 2.74138 11.4854 3.00358 11.4877C3.26578 11.49 3.51838 11.3892 3.70698 11.207L3.99998 10.914V17.5C3.99998 17.7652 4.10534 18.0196 4.29287 18.2071C4.48041 18.3947 4.73476 18.5 4.99998 18.5H6.99998C7.2652 18.5 7.51955 18.3947 7.70709 18.2071C7.89462 18.0196 7.99998 17.7652 7.99998 17.5V15.5C7.99998 15.2348 8.10534 14.9805 8.29287 14.7929C8.48041 14.6054 8.73476 14.5 8.99998 14.5H11C11.2652 14.5 11.5196 14.6054 11.7071 14.7929C11.8946 14.9805 12 15.2348 12 15.5V17.5C12 17.7652 12.1053 18.0196 12.2929 18.2071C12.4804 18.3947 12.7348 18.5 13 18.5H15C15.2652 18.5 15.5196 18.3947 15.7071 18.2071C15.8946 18.0196 16 17.7652 16 17.5V10.914L16.293 11.207C16.4816 11.3892 16.7342 11.49 16.9964 11.4877C17.2586 11.4854 17.5094 11.3803 17.6948 11.1948C17.8802 11.0094 17.9854 10.7586 17.9877 10.4964C17.9899 10.2342 17.8891 9.98163 17.707 9.79303L10.707 2.79303Z" fill="#374151"/>
                                            </svg>
                                            <p className="text-[#374151] text-[14px] font-medium pl-[15px]">Home</p>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <input type="text" className="h-[37px] rounded-[8px] border-2 border-[#E5E7EB] w-[147px] sm:w-[250px] lg:w-[300px] pl-[42px]" placeholder="Search" onChange={(e) => searchHandle(e)}/>
                                        <div className="absolute top-[50%] translate-y-[-50%] pl-[18px]">
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 14.5L11.1 11.6M12.6667 7.83333C12.6667 10.7789 10.2789 13.1667 7.33333 13.1667C4.38781 13.1667 2 10.7789 2 7.83333C2 4.88781 4.38781 2.5 7.33333 2.5C10.2789 2.5 12.6667 4.88781 12.6667 7.83333Z" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-[24px] w-full h-full">
                                    <div className="flex flex-col lg:grid lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                        {filterSearch.map((result, key) => (
                                            <div className="bg-[#FFFFFF] rounded-[8px] w-full shadow-md" key={key}>
                                                {result.colmachineurl01 === '' ?
                                                    <img src={process.env.PUBLIC_URL+'/img/blank.png'} alt="blank.png" className="w-full h-[250px] md:h-[350px] lg:h-[230px] xl:h-[260px] object-cover rounded-tl-[8px] rounded-tr-[8px]"/>
                                                : 
                                                    <iframe className="w-full h-[250px] md:h-[350px] lg:h-[230px] xl:h-[260px] object-cover rounded-tl-[8px] rounded-tr-[8px]" src={result.colmachineurl01} title="description"></iframe>
                                                }
                                                <div className="p-[20px]">
                                                    {/* <div className="flex flex-row justify-between items-center mb-[14px]">
                                                        <div>
                                                            <p className="text-[#6B7280] font-medium">Camera</p>
                                                            <Switch height={20} width={40}/>
                                                        </div>
                                                        <div>
                                                            <ul className="flex flex-row justify-between">
                                                                <li className="border-2 py-[6px] px-[12px] rounded-l-[8px] bg-[#E1EFFE]">1</li>
                                                                <li className="border-t-2 border-b-2 py-[6px] px-[12px]">2</li>
                                                                <li className="border-2 py-[6px] px-[12px] rounded-r-[8px]">3</li>
                                                            </ul>
                                                        </div>
                                                    </div> */}

                                                    <div className="mb-[14px] flex flex-col justify-between items-center overflow-auto">
                                                        {switch_element(result)}
                                                        <div className="flex flex-col items-center w-full">
                                                            <button className="bg-green-700 w-full mt-[12px] text-white p-[8px] rounded-[8px]" onClick={() => startHandle(result.colmachinecode)}>Start</button>
                                                            <button className="bg-red-700 w-full mt-[12px] text-white p-[8px] rounded-[8px]" onClick={() => maintenance(result.colmachinecode)}>maintenance</button>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    <h2 className="text-[24px] font-medium text-[#111928]">{result.colmachinecode}</h2>
                                                    <div className="flex flex-row justify-between">
                                                        {rewardValue(result)}
                                                    </div>

                                                    <div className="mt-[12px] w-full overflow-auto">
                                                        {resultMachineRealTime(result)}
                                                    </div>

                                                    <Link to={'/detail/'+result.colmachinecode} className="bg-[#1A56DB] w-full mt-[12px] flex flex-row text-white justify-center items-center gap-3 p-[8px] rounded-[8px]">
                                                        <label className="block text-[15px]">Details</label>
                                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.23438 3.13438C8.3844 2.9844 8.58784 2.90015 8.79998 2.90015C9.01211 2.90015 9.21555 2.9844 9.36557 3.13438L14.1656 7.93438C14.3156 8.0844 14.3998 8.28784 14.3998 8.49998C14.3998 8.71211 14.3156 8.91555 14.1656 9.06558L9.36557 13.8656C9.21469 14.0113 9.01261 14.0919 8.80285 14.0901C8.5931 14.0883 8.39245 14.0042 8.24412 13.8558C8.09579 13.7075 8.01166 13.5069 8.00984 13.2971C8.00801 13.0873 8.08865 12.8853 8.23438 12.7344L11.6688 9.29998H2.39998C2.1878 9.29998 1.98432 9.21569 1.83429 9.06566C1.68426 8.91563 1.59998 8.71215 1.59998 8.49998C1.59998 8.2878 1.68426 8.08432 1.83429 7.93429C1.98432 7.78426 2.1878 7.69998 2.39998 7.69998H11.6688L8.23438 4.26558C8.0844 4.11555 8.00015 3.91211 8.00015 3.69998C8.00015 3.48784 8.0844 3.2844 8.23438 3.13438Z" fill="white"/>
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </NotAuthenticated>
        </>
    );
}

export default Home;