import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const inputPassword = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logHandle = () => {
        if(inputPassword.current.value === 'Astronaut789'){
            dispatch({
                type: 'AUTH',
                newUuid : 'Astronaut789'
            });

            navigate('/home');
        }else{
            inputPassword.current.value = '';
            navigate('/');
        }
    }

    document.title = 'Login';
    return(
        <>
            <div className="bg-[#EDF5FE] w-full min-h-full flex flex-col">
                <div className="bg-white w-full sm:w-[500px] shadow-lg m-auto mt-10 p-[10px] rounded-md">
                    <h3 className="text-black font-medium mb-3 text-[20px]">Password</h3>
                    <form onSubmit={logHandle}> 
                        <div className="mb-3">
                            <input type="password" className="border-2 w-full h-[40px] rounded-md text-center" ref={inputPassword}/>
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="bg-blue-500 w-full h-[40px] rounded-md text-white font-medium">Log In</button>
                        </div>
                    </form>
                    <hr/>
                    <p className="text-blue-600 py-2 cursor-pointer" onClick={() => navigate('/documentation')}>Documentation</p>
                </div>
            </div>
        </>
    );
}

export default Login;